import tw from "tailwind-styled-components"

export const StyledContainer = tw.div`
    flex
    flex-col
    content-start
    items-center
    w-full
    h-screen
    bg-sand
`
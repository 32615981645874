// this is going to be the main page

import Container from "../../components/Container/Container";
import Hero from "../../components/Hero/Hero";

const Main = () => {
    return (
    <Container>
        <Hero />

        <h1>More content will be here</h1>
    </Container>
    );
  }
  
  export default Main;